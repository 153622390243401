{
  "apiHost": "https://hebrides-backend.dev.stackenterprise.co",
  "AppInsights": {
    "BackendHost": "hebrides-backend.dev.stackenterprise.co",
    "InstrumentationKey": "5336c05a-3823-47f6-bf30-9e71a5fb3831"
  },
  "UserAuth": {
    "TenantId": "5ecec5bd-089d-4d8b-b2c1-5024715d6e42",
    "ClientId": "89ba2116-765b-49a3-93cc-ee140ea5b185",
    "SpecialAccessGroups": [
      "f52ab712-62b6-4d6d-ac26-3e65b0ea9bc5"
    ]
  }
}
